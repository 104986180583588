import { axiosInstance } from "./axiosInstance";

const nitroposURL = process.env.REACT_APP_NITROPOS;

const getSites = async () => {
    return axiosInstance
        .get("https://nitrous.rt-itservices.co.uk/sites/data")
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getSuppliers = async () => {
    return axiosInstance
        .get(`${nitroposURL}/eposSQL/getSuppliers`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getPicklistStatuses = async () => {
    return axiosInstance
        .get(`${nitroposURL}/picklist/statuses`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getAllPicklists = async (limit: number, page: number) => {
    return axiosInstance
        .post(`${nitroposURL}/picklist/all`, { limit, page })
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getPickListById = async (id: number) => {
    return axiosInstance
        .get(`${nitroposURL}/picklist/list/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getCategories = async () => {
    return axiosInstance
        .get(`${nitroposURL}/eposSQL/warehouse/categories`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getProductsByCatId = async (id: number) => {
    return axiosInstance
        .post(`${nitroposURL}/eposSQL/warehouse/categories/products`, {
            categoryId: id,
        })
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getProductsBySupId = async (id: number) => {
    return axiosInstance
        .post(`${nitroposURL}/eposSQL/warehouse/supplier/products`, {
            supplierId: id,
        })
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

const getProductsByVal = async (val: string) => {
    return axiosInstance
        .get(`${nitroposURL}/eposSQL/warehouse/product/search/${val}`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return "error";
        });
};

export {
    getSites,
    getSuppliers,
    getPicklistStatuses,
    getAllPicklists,
    getPickListById,
    getCategories,
    getProductsByCatId,
    getProductsBySupId,
    getProductsByVal,
};
